<template>
  <div>
    <a-row :gutter="12">
      <a-col :sm="24" :md="12" :lg="8" :xl="6" v-for="item in data"
             :key="item.uri">
        <a-card hoverable class="default statistics-click" @click="clickDetail(item)">
          <template slot="title">
            <a-tooltip
              style="color: blueviolet"
              placement="top"
              :title="item.apiName">
              {{ item.uri }}
            </a-tooltip>
          </template>
          <p>调用次数：{{ item.invokeCount }}</p>
          <p>接口描述：{{ item.apiName.length > 18 ? item.apiName.substring(0, 18) + '...' : item.apiName}}</p>
        </a-card>
      </a-col>
    </a-row>
    <a-modal
      :title="title"
      :maskClosable="false"
      v-model="visible"
      width="800px"
      @cancel="cancel"
    >
      <detail ref="detailRef"></detail>
      <a-button slot="footer" @click="cancel">取消</a-button>
    </a-modal>
  </div>
</template>
<script>
import SERVICE_URLS from '@/api/service.url'
import Detail from './Detail'

export default {
  components: { Detail },
  data() {
    return {
      data: [],
      visible: false,
      title: ''
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$http(this, {
        url: SERVICE_URLS.invokeApi.view,
        noTips: true,
        success: (data) => {
          this.data = data.body
        },
        error: () => {
          this.pageLoading = false
        }
      })
    },
    clickDetail(item) {
      this.visible = true
      this.$nextTick(() => {
        this.title = item.uri
        this.$refs.detailRef.setData(item.invokeDetails)
      })
    },
    cancel() {
      this.visible = false
    }
  }
}
</script>
<style></style>