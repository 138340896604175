<template>
  <div>
    <template>
      <a-table
        :pagination="false"
        :columns="columns"
        bordered size="small"
        :data-source="data"
        :rowKey="(record) => record.username"
        @click.stop>
        <template slot="_index" slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
      </a-table>
    </template>
  </div>
</template>
<script>

export default {

  data() {
    return {
      data: [],
      columns: [
        {
          title: '序号',
          width: 50,
          align: 'center',
          scopedSlots: { customRender: '_index' }
        },
        {
          title: '用户名',
          align: 'center',
          dataIndex: 'username',
          width: 200
        },
        {
          title: '调用次数',
          dataIndex: 'invokeCount',
          align: 'center',
          width: 100
        }
      ]
    }
  },
  watch: {
    data(val) {
      this.data = val
    }
  },
  methods: {
    setData(data) {
      this.data = data
    }
  }
}
</script>
<style>

</style>